import Gallery from '@browniebroke/gatsby-image-gallery'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import * as styles from '../../assets/css/artful.module.css'
import Layout from '../../components/Layout'

const essayQuery = graphql`
  query {
    content(uid: { eq: "c11" }) {
      image1 {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      image2 {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      image3 {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      image4 {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      image5 {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      image6 {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      image7 {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      image8 {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      image9 {
        childImageSharp {
          thumb: gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`

const TownArchitechHistory = () => {
  const { content } = useStaticQuery(essayQuery)

  return (
    <Layout>
      <section>
        <h1 className='headline'>The Role of the Town Architect</h1>
        <section>
          <h2>About Seaside</h2>
            <p>
            By Robert Davis<br />
            Founder, Seaside Florida<br /><br />
            Teófilo Victoria was the Seaside’s first Town Architect. With his droll sense of humor, he seemed like the member of the “Night Crew” who should to stay in Seaside after the charrette of summer, 1983 and help those people who were “winging it” figure out what to do next. This charrette allowed us to get almost all of the ideas for Seaside down on several large sheets of paper in a fortnight. The group of young architects who participated in the charrette spent the  better part of the following year refining the Seaside Plan and Code, coming into the office of Duany Plater-Zyberk at night when the work that paid the rent could be pulled off the drafting tables and the Seaside drawings could be worked on; thus they came to be referred to as the “Night Crew”.
            </p>
            <div>
              <p>
              The term “charrette” came to mean a concentrated effort to do a plan in a short period of time by assembling most of those who would need to make decisions about the plan, particularly public officials. Seaside’s charrette may have been the first modern charrette but didn’t have much public input, since in 1982, Florida’s Walton County was one of the last bastions of unfettered free enterprise, lacking zoning or building officials.
              </p>
              <p>
              We had, by the time of the first charrette, received an anonymous letter from Seagrove, complaining about Seaside’s “tin- topped shacks and ship-shod (sic) construction” and predicting the ruination of property values in Seagrove, next door to the slum we were building. But county government had not yet given C.A.V.E. people (Citizens Against Virtually Everything) a forum for opposing anything that displeased them, nor were the C.A.V.E. people so numerous or vocal in the early eighties in N.W. Florida as they are now.
              </p>
              <div className={styles.artfulImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.image1.childImageSharp]}
              />
              Letter from local homeowners, October 1981
            </div>
              <p>
              The Seaside Charrette was, nevertheless, no day at the beach. Days at the beach, over several years, drawing in the sand, building sand castles models and walking and talking endlessly, had already determined most of the essential ideas of Seaside. The charrette was a bit like a military campaign, with many of the hardships customarily encountered in such, including sleeping in Quonset huts, marching through mud and working in incredibly inclement weather. Fortunately, the savagery and butchery of a military campaign was absent; no one was carrying weapons more dangerous than pencils and t-squares.
              </p>
            </div>
            <p>
            The original members of the “Night Crew”—Teófilo Victoria, Victoria Casasco, Ernesto Buch, and Luis Trelles (later joined by Maria de la Guardia and Jorge Trelles)—under the supervision of Andrés Duany, slept in a Quonset hut that Willie and Leah Mason found at an Air Force auction and erected for the occasion. It had been military surplus because the canvas was no longer watertight, and it rained all day and all night for the two-week long charrette. Much of the drawing was done on the porch of our house in Seaside, “The Yellow House”, so the plans had the wrinkled and mottled character of vellum soaked in a water, ink and caffeine solution to age it and give it a certain gravitas. The role of the Town Architect was an important topic of discussion during the 14 days. It was clear that the code that was being drawn up, along with plans, street sections and sketches, would require interpretation and administration and it was hoped that purchasers of property might hire the Town Architect to design a cottage.
            </p>
            <div className={styles.artfulImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.image2.childImageSharp]}
              />
              The “Night Crew” exiting the Quonset hut, 1983
            </div>
            <p>
            But in spite of the obvious need for oversight of design and the less obvious enticement of possible commissions and building projects, not one of the Night Crew members would volunteer, so we resorted to conscription. As the Night Crew was about to drive to the airport after their 14 days in Seaside, Teófilo was called up for a last minute consultation, during which the Night Crew drove off. Teófilo didn’t notice the car driving away, and at first he was sure that it was only a joke and they’d be back soon. A couple of hours later it began to appear that he’d been abandoned, and a couple of hours after that, a telegram arrived, sent by Andrés, with a quote from Horatio Nelson “England expects that every man will do his duty.”
            </p>
            <div className={styles.artfulImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.image3.childImageSharp]}
              />
              The original “Night Crew” at work in the Yellow House, 1983
            </div>
            <p>
            After some weeks, Ernesto Buch was persuaded to go to Seaside to relieve Teófilo, but by then Teófilo had met Jim Foley, one of Seaside’s philosopher-carpenters, and they had begun a project to convert the Seaside Shrimp Shack, an eight foot square plywood shack from which I had sold shrimp and gumbo, into the “Tempietto Gamberaia”, the temple to the shrimper and a sly reference to one of our favorite spots, the Villa Gamberaia (later Villa Lante) by Vignola, in Bagnaia. Teófilo and Ernesto, working with Jim Foley, transformed the tiny shack into a work of carpenter classicism. It became the head building of Per-spi-cas-ity, designed by Deborah Berke and Douglas Duany to transform Seaside’s outdoor market, from tables with canvas into eight tiny huts around a terraced courtyard. At night, if you squinted, it resembled a quarter-scale model of the Pompeian Forum.
            </p>
            <div className={styles.artfulImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.image4.childImageSharp]}
              />
              The Tupelo Gazebo, 1984
            </div>
            <p>
            Douglas, Deborah, Derrick Smith and Maria De La Guardia arrived in 1984. The sharecropper’s cabin they lived in with Teófilo and Ernesto looked like Appalachia but with the population density of Hong Kong. Ernesto’s long simmering stews, with black beans and rice, fed the residents of the Town Architect’s house and anyone lucky enough to show up at the right time. Deborah organized reading groups in which we discussed the meaning of chapters from Heidegger and Kenneth Frampton (among the most memorable and least readable of our assignments). Foley was often in attendance. He had studied philosophy with Robert Pirsig, before deciding that carpentry, like motorcycle maintenance, was a pursuit more consistent with his philosophy than teaching.
            </p>
            <p>
            Many of the dinners, and the wine — fueled discussions, took place in the Tupelo Gazebo, Seaside’s first public building. The Gazebo sat in the middle of Tupelo Circle and had been started by two carpenters working for Seaside, Charlie Connor and Skipper Williams. After finishing a floor with radiating planks, they puzzled over what to do next; I remember daily sessions with Tom Stein, who was running construction, Andrés, Willie Mason, Ernesto or Teófilo, pointing out with long sticks how crude columns could be classicized with funky capitals and an entablature that was equally ad hoc. The word amateur describes this building perfectly. The root amateur is the same as love, and the Tupelo Gazebo is one of Seaside’s most loved icons.
            </p>
            <div className={styles.artfulImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.image5.childImageSharp]}
              />
              Ernesto Buch‘s Tupelo St. Beach Pavilion
            </div>
            <p>
            The plan approval process assumed that most plans would be drawn up by amateurs, by the owners themselves, by their carpenters or by drafting services. I would often help owners draw up plans that combined simplicity and a fair degree of vernacular verisimilitude. Suellen Hudson, our most successful spec builder, would build a new variation of The Yellow House each year, refining and improving it each time and selling it at a considerably improved price each time.
            </p>
            <p>
            The Code was kept deliberately loose, to allow for variety and to encourage heterogeneity. The first line of the Code was, “Variances shall be granted for architectural merit, but not for hardship”. In the early years, casual conversations on the beach between homeowners and the town architect or myself would often be construed as variance decisions. In time a booklet, “The Design Approval Process” was published and given to homeowners and their designers at the beginning of the plan submittal process. Andrés had discovered a line by Jorge Luis Borges, suggesting that bureaucracies move at the deliberate pace of planets and vegetables, justifying, we thought, a process that some homeowners might have viewed as cumbersome and opaque. 
            </p>
            <div className={styles.artfulImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.image6.childImageSharp]}
              />
              Deborah Berke’s “Panhandle”
              photo credit: Steven Brooke
            </div>
            <p>
            Ernesto’s masterpiece in Seaside is the Tupelo Beach Pavilion. Placed over George’s Gorge, a path cut to the beach by generations of jeeps, the Pavilion is a bridge, a gateway and a communal front porch for the neighborhood. Its image, a classical arch framing sea and sky, was used by Eastern Airline and the State’s tourism department to promote travel to Florida.  
            </p>
            <p>
            Deborah Berke’s cottages in Seaside were a bit larger, but combined a modernist and Shaker austerity that might also have served as a model for an architecture that exemplified “the luxury of simplicity”, Seaside’s early slogan.  
            </p>
            <p>
            Derrick Smith’s designs for Seaside’s first pool-house and for the Seaside Pavilion, overlooking the beach in the center of town, were purposefully monumental, but also laconic and restrained in their detailing.  
            </p>
            <div className={styles.artfulImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.image7.childImageSharp]}
              />
              Richard Gibb’s “Proteus”
            </div>
            <p>
            Tom Christ, whose tenure as Town Architect followed Derrick’s, was probably the most prolific builder of Seaside structures. His conversion of the shack housing our sauna into a classical pavilion helped center the public realm of the Rosewalk, a grouping of cottages around a garden done by Robert Orr and Melanie Taylor. He also designed the Savannah Pavilion, Seaside’s second beach walkover, and designed the grouping of houses around it.  
            </p>
            <p>
            Tom moved from Miami to the Seaside area for the duration, and his proximity as well as his experience and skill made him a natural choice for designing houses for numerous Seaside property owners.  
            </p>
            <p>
            Victoria Casasco designed a beautiful steel and canvas structure for Bud and Alley’s Oyster Bar. Unfortunately, neither the Oyster Bar nor the structure lasted very long. But the Appell house, with its louvered upper level living space, was another building that combined modernist and vernacular ideas.  
            </p>
            <div className={styles.artfulImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.image8.childImageSharp]}
              />
              Leo Casas’ addition to Chatham House
            </div>
            <p>
            John Massengale designed one of the houses near the Savannah Pavilion and sketched the Athenaeum, a building for Seaside’s Central Square. He also designed a new configuration for beach cottages for the western part of town.
            </p>
            <p>
            Charles Warren designed a new Post Office for Seaside (unbuilt) and a park on the western border, with a sundial, a ziggurat and a curving path leading to a secret seating area. (Unfortunately, the seating, itself, has long since disappeared, since the entire park is so secret that only children discover it and use it.) Chip’s two houses in Seaside are among the town’s best classical buildings, though the one next to the Tupelo Pavilion, because of its size, almost upstages Ernesto’s masterpiece.
            </p>
            <p>
            Richard Gibbs was a homeowner when he became Town Architect and was thus able to design more houses than most of the other Town Architects. He was also Town Architect for the longest period to date and applied his organizational skills to making the architectural review process more professional, more organized and more predictable.
            </p>
            <div className={styles.artfulImg}>
              <Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.image9.childImageSharp]}
              />
              Stuart Cohen and Anders Nereim’s East Ruskin St. Pavilion
            </div>
            <p>
            Leo Casas worked on several projects for downtown Seaside that have not yet been realized, but was able to build several houses, including a very large house on the beach.
            </p>
            <p>
            Leo’s remodeling of the Chatham House, adding a tower, a pool and making it more conventionally luxurious, was respectful of Walther Chatham’s style. But it also highlighted the need for Seaside to develop a program to protect architectural icons, particularly modest houses from the early years that provide little of the opulence that buyers in recent years seem to consider essential to the good life.
            </p>
            <p>
            Ty Nunn, Seaside’s current Town Architect, had already worked for years in Seaside, first with Richard Gibbs and later as a partner with Cheryl Troxel. The economic downturn gave him enough time to take over this important role, and he has worked diligently to update the Code and bring its many modifications over the years into a coherent document.
            </p>
            <p>
            Ty’s tenure has overlapped with a struggle over transitioning the architectural review process from one that is managed by the founder to one that can survive the founder’s eventual departure. For at least seven years, I have written memoranda to members of Seaside’s Town Council, expressing my view that homeowner politics generally work badly in managing architectural review. Seaside is a place worth caring for but should not be preserved in amber. Homeowner and municipal architectural review committees tend to be conservative and conventional as well as biased toward friends and against those who are not. My current thinking is to turn the Seaside Architectural Review Committee over to a non-profit whose directors and staff would have the experience and capacity to oversee future generations of Town Architects. My hope is that a future Town Council will support this transition and provide financial support for the program, but this may end up being one of the programs that is supported by a gift from the Founders.
            </p>
            <p>
            In the early years, the Town Architects were struggling along with the rest of us to recover the art and craft of town building; the role of the Town architect in that process needed to be rediscovered and renewed in order to manage the building of a town by numerous individuals who all had individual reasons why their ideas for their private domain was optimal but who might not have considered how those ideas would affect the public realm. The Seaside Code was written and drawn primarily to shape the public realm, to form streets that would feel like outdoor rooms and would be comfortable for human beings on foot, particularly small ones.
            </p>
            <p>
            In our travels we had seen and experienced towns where the streets and squares felt particularly comfortable, and we had measured, photographed and sketched these places as part of our research. We tried not to invent anything, but rather to put into place a simple set of ground rules that we thought would likely produce streets and squares with the coherence, comfort and pleasure that had characterized those places we had liked best.
            </p>
            <p>
            Town Architects are not the only architects to have left significant imprints on Seaside. Both Alex Gorlin and Walter Chatham bought property in Seaside and built iconic houses for themselves as well as numerous houses for friends and neighbors. Robert A.M. Stern designed a hotel in the early eighties when we thought we had a hotelier lined up to develop and run it. It would have been a wonderful addition to Seaside, but the beach house that Bob and Gary Brewer, his associate, did build has become an icon, as will be the beach pavilion the owner commissioned that might be built if his neighbors and street association can reach agreement on its funding. And of course the designers of the beach pavilions, all of them icons in their different ways, have contributed to the public realm of Seaside in important ways.
            </p>
            <p>
            The pavilions celebrate public space and the daily ritual of walking to the beach in ways that, we hope, will convince new generations of Americans that public life is worth investing in. This goal of Seaside is, like many of its goals, similar to those of the Arts and Crafts movement of the previous century, a struggle to maintain humane values against a tide of industrial and financial transformations that seem to inundate not just a world made by hand, but the worth of those whose hands once made that world and those who would live in a world made to the measure of man.
            </p>
        </section>
      </section>
    </Layout>
  )
}

export default TownArchitechHistory
